
<template>
  <div class="my-second info bgcolor">
      <!-- :title="$t('info.reset_two_psd')"  -->
      <Head  :title="$t('info.get_two_psd')"  :show="true" ></Head>
      <div class="common-box info-box">
          <div class="pass-main">
                <van-form @submit="onSubmit" class="pass-form" ref="passform">
                    <van-cell-group class="form-box ">
                        <van-field
                            v-model="obj.area"
                            type="text"
                            name="area"
                            :label="$t('info.c_area')"
                            :rules="[]"
                            right-icon="arrow"
                            readonly
                            class="van-nohidden van-countrynum"
                            @click.stop="showSome"
                        >
                            <template #input>
                                <div class="tel-qu width100">
                                    <AreaNum ref="choosenum" @excountry="getCountryItem"  country-code="th"/>
                                    <span class="my-country">{{obj.country_name}}</span>
                                </div>
                            </template>
                        </van-field>
                        <van-field 
                            type="tel"  
                            :label="$t('login.phone')" 
                            name="phone"
                            v-model="obj.phone" 
                            :placeholder="$t('info.input_phone_num')" clearable 
                            :rules="[{ required: true, message:$t('login.empty_phone')},{pattern:phoneReg,message:$t('login.err_phone')}]"
                        />
                        <van-field
                            maxlength="6"
                            v-model="obj.code"
                            type="text"
                            name="code"
                            :label="$t('login.captcha')"
                            :placeholder="$t('login.enter')+$t('login.captcha')"
                            :rules="[{ required: true, message:$t('login.required_captcha')},{pattern:smsReg,message:$t('login.ture_captcha'),trigger:'onBlur'}]"
                        >
                            <template #button>
                                <SmsDemo :phone="obj.phone" type="1"  :area="obj.area" ></SmsDemo>
                            </template>
                        </van-field>
                            <van-field
                                v-model="obj.newPassword"
                                :type="pt2"
                                name="newPassword"
                                @blur="validatorPass"
                                :right-icon="pt2=='password'?'eye':'closed-eye'"
                                :label="$t('info.new_two_psd')"
                                :placeholder="$t('login.enter')+$t('info.new_two_psd')+$t('login.pass_rule')"
                                @click-right-icon="changePassType2"
                                :rules="[
                                { required: true, message: $t('login.required_sec_pass') },
                                { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'}
                                ]"
                            />
                            <van-field
                                v-model="obj.newPasswordrepeat"
                                :type="pt1"
                                name="newPasswordrepeat"
                                :right-icon="pt1=='password'?'eye':'closed-eye'"
                                :label="$t('info.conform_two_psd')"
                                :placeholder="$t('info.conform_two_psd')"
                                @click-right-icon="changePassType1"
                                :rules="[{ required: true, message: $t('login.required_sec_dbpass') },
                                    { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'},
                                    {
                                        validator:validatordbPass,message:$t('login.secpass_equal_error'),trigger:'onBlur'
                                    }
                                ]"
                            />
                    </van-cell-group>
                </van-form>
                <van-button class="login-btn" @click="changepass">{{$t('common.confirm')}}</van-button>
          </div>
      </div>
  </div>
</template>

<script>
import {passReg,phoneReg,smsReg} from "@/common/validate"
import {Notify} from "vant"
import {areaCode} from "@/common"
export default {
    data(){
        return {
            obj:{
                area:"66",
                ...areaCode
            },
            pt3:"password",
            pt2:"password",
            pt1:"password",
            passPattern:passReg,
            phoneReg,
            smsReg,
        }
    },
    methods:{
        showSome(){
            this.$refs.choosenum.hideSubMenu = false;   // 显示国旗 
        },
        getCountryItem(data){
            this.obj.area = data.dialCode;
            this.obj.country_name = data.name;
        },
        validatorPass(val){
            if(this.obj.newPasswordrepeat){
                this.$refs.passform.validate('newPasswordrepeat');
            }
        },
        validatordbPass(val){
            if(this.obj.newPassword){
                return val === this.obj.newPassword;
            }  
        },
        changepass(){
            this.$refs.passform.submit();
        },
        changePassType3(){
            this.pt3 = this.pt3 == "password"?"text":"password"
        },
        changePassType2(){
            this.pt2 = this.pt2 == "password"?"text":"password"
        },
        changePassType1(){
            this.pt1 = this.pt1 == "password"?"text":"password"
        },
        onSubmit(value){
            this.$ajax.resetpin({
                ...this.obj,
                pin:this.obj.newPassword
            })
            .then(res=>{
                if(res.code==200){
                    Notify({ type: 'success', message: res.msg });
                }
            })
        }
    }
}
</script>
